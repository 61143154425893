// @ts-ignore
import React, { useRef, useEffect, useState, useMemo } from 'react'
import { Skeleton, Empty } from 'antd'
import classNames from 'classnames'
import './index.scss'
import Legends from '../Legends'
import { drawChart } from './chart.utils'
import { isEmpty } from 'lodash'
import { COLOR_PALLETTE } from 'Utils/constants'
type options = {
  percentToFixed: number
}
const PieChart = ({
  data,
  showLegend = true,
  isVertical,
  className = '',
  centerText = '',
  options = {},
}) => {
  const pieChartRef = useRef<HTMLCanvasElement>(null)
  const [customizedLegends, setCustomizedLegends] = useState()
  useEffect(() => {
    let chart = drawChart(
      data,
      pieChartRef,
      {
        centerText,
      },
      options
    )
    return () => {
      return chart?.destroy()
    }
  }, [data])

  const PieChart = useMemo(() => {
    if (!data || isEmpty(data)) return <Empty description={false}></Empty>
    return (
      <div
        className={classNames('PieChartComponent', className)}
        style={{ flexDirection: isVertical && 'column-reverse' }}
      >
        <div style={{ position: 'relative', display: isVertical ? 'block' : 'flex' }}>
          <div style={{ height: isVertical && showLegend && '50%' }} className="chart-content">
            <canvas ref={pieChartRef} className="chart"></canvas>
          </div>
          {showLegend && (
            <Legends
              className="mt-2"
              legends={Object.keys(data)?.map((legend, index) => ({
                label: legend,
                color: COLOR_PALLETTE[index],
                shape: 'rounded',
              }))}
            ></Legends>
          )}
        </div>
      </div>
    )
  }, [data, customizedLegends])

  return PieChart
}

export default PieChart
