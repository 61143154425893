import React from 'react'

const StatusBadge = ({ status }) => {
  const computeStatus = (status) => {
    switch (status) {
      case 'New':
        return (
          <div
            className="rounded flex items-center justify-center px-2"
            style={{
              backgroundColor: '#E6F7FF',
              color: '#1890FF',
              width: 'max-content',
              fontWeight: 600,
            }}
          >
            New
          </div>
        )
      case 'Yellow':
        return (
          <div
            className="rounded flex items-center justify-center px-2"
            style={{
              backgroundColor: '#FFFBE6',
              color: '#FAAD14',
              width: 'max-content',
              fontWeight: 600,
            }}
          >
            Yellow
          </div>
        )
      case 'Green':
        return (
          <div
            className="rounded flex items-center justify-center px-2"
            style={{
              backgroundColor: '#F6FFED',
              color: '#52C41A',
              width: 'max-content',
              fontWeight: 600,
            }}
          >
            Green
          </div>
        )
      case 'Deactive':
        return (
          <div
            className="rounded flex items-center justify-center px-2"
            style={{
              backgroundColor: '#FAFAFA',
              color: '#8C8C8C',
              width: 'max-content',
              fontWeight: 600,
            }}
          >
            Deactive
          </div>
        )
      case 'Star':
        return (
          <div
            className="rounded flex items-center justify-center px-2"
            style={{
              backgroundColor: '#FFF7E6',
              color: '#FA8C16',
              width: 'max-content',
              fontWeight: 600,
            }}
          >
            ⭐ Star
          </div>
        )
      case 'Red':
        return (
          <div
            className="rounded flex items-center justify-center px-2"
            style={{
              backgroundColor: '#FFF1F0',
              color: '#F5222D',
              width: 'max-content',
              fontWeight: 600,
            }}
          >
            Red
          </div>
        )
      case 'Clear':
        return (
          <div
            className="rounded flex items-center justify-center px-2"
            style={{
              backgroundColor: '#FAFAFA',
              color: '#8C8C8C',
              width: 'max-content',
              fontWeight: 600,
            }}
          >
            Clear
          </div>
        )
      case 'Promotion':
        return (
          <div
            className="rounded flex items-center justify-center px-2"
            style={{
              backgroundColor: '#F6FFED',
              color: '#52C41A',
              width: 'max-content',
              fontWeight: 600,
            }}
          >
            Promotion
          </div>
        )
      case 'Over':
        return (
          <div
            className="rounded flex items-center justify-center px-2"
            style={{
              backgroundColor: '#FFFBE6',
              color: '#FAAD14',
              width: 'max-content',
              fontWeight: 600,
            }}
          >
            Over
          </div>
        )
      case 'Short':
        return (
          <div
            className="rounded flex items-center justify-center px-2"
            style={{
              backgroundColor: '#FFF1F0',
              color: '#F5222D',
              width: 'max-content',
              fontWeight: 600,
            }}
          >
            Short
          </div>
        )
      case 'OOS':
        return (
          <div
            className="rounded flex items-center justify-center px-2"
            style={{
              backgroundColor: '#FFEC3D',
              color: '#F5222D',
              width: 'max-content',
              fontWeight: 600,
            }}
          >
            OOS
          </div>
        )
      case 'OK':
        return (
          <div
            className="rounded flex items-center justify-center px-2"
            style={{
              backgroundColor: '#FAFAFA',
              color: '#8C8C8C',
              width: 'max-content',
              fontWeight: 600,
            }}
          >
            OK
          </div>
        )
      case 'Profit':
        return (
          <div
            className="rounded flex items-center justify-center px-2"
            style={{
              backgroundColor: '#FAFAFA',
              color: '#8C8C8C',
              width: 'max-content',
              fontWeight: 600,
            }}
          >
            Profit
          </div>
        )
      case 'Traffic':
        return (
          <div
            className="rounded flex items-center justify-center px-2"
            style={{
              backgroundColor: '#FAFAFA',
              color: '#8C8C8C',
              width: 'max-content',
              fontWeight: 600,
            }}
          >
            Traffic
          </div>
        )
      case 'Image':
        return (
          <div
            className="rounded flex items-center justify-center px-2"
            style={{
              backgroundColor: '#FAFAFA',
              color: '#8C8C8C',
              width: 'max-content',
              fontWeight: 600,
            }}
          >
            Image
          </div>
        )
    }
  }
  return <>{computeStatus(status)}</>
}

export default StatusBadge
