import React from 'react'
import { BestSellerDataType } from '../constants'
import img from 'Assets/images/product_img.png'
import { ReactComponent as Redirect } from 'Assets/images/redirect.svg'
import StatusBadge from './StatusBadge'
import './index.scss'
import { Divider, Empty, Popover } from 'antd'
import kFormatter, { thousandsSeparator } from 'Utils/kFormatter'

const BestSeller_Card = ({ data }: { data: BestSellerDataType }) => {
  return (
    <div className="BestSeller-wrapper flex flex-col bg-white mb-4">
      <div className="BestSeller-upper-area flex flex-row">
        <div className="BestSeller-upper-left flex flex-col">
          <div className="BestSeller-upper-img-area">
            {!data?.image ? (
              <Empty className="BestSeller-upper-img" style={{ width: 200, height: 200 }}></Empty>
            ) : (
              <img
                className="BestSeller-upper-img"
                src={data?.image}
                style={{ width: 200, height: 200 }}
              />
            )}
            <div
              className="flex flex-row justify-between"
              style={{ position: 'relative', top: '-89%' }}
            >
              <div className="BestSeller-upper-tag"> #{data?.ranking}</div>
              <div className="BestSeller-upper-url cursor-pointer">
                <Redirect onClick={() => window.open(data?.url, '_self')}></Redirect>
              </div>
            </div>
          </div>
          <div className="BestSeller-upper-img-desc">
            <div className="flex flex-row justify-between items-center">
              <div className="BestSeller-upper-img-desc-text">Product Performance</div>
              {/* <div
                className="flex align-center justify-center"
                style={{
                  backgroundColor: '#FFF1F0',
                  color: '#F5222D',
                  fontSize: 12,
                  fontWeight: 600,
                  width: 29,
                  height: 22,
                  borderRadius: 2,
                }}
              >
                Red
              </div> */}
              <StatusBadge status={data?.product_performance}></StatusBadge>
            </div>
            <div className="flex flex-row justify-between mt-2 items-center">
              <div className="BestSeller-upper-img-desc-text">Inventory Status</div>
              {/* <div
                className="flex align-center justify-center"
                style={{
                  backgroundColor: '#FAFAFA',
                  color: '#8C8C8C',
                  fontSize: 12,
                  fontWeight: 600,
                  width: 29,
                  height: 22,
                  borderRadius: 2,
                }}
              >
                OK
              </div> */}
              <StatusBadge status={data?.inventory_status}></StatusBadge>
            </div>
          </div>
        </div>
        <div className="BestSeller-upper-right">
          <div className="BestSeller-upper-right-title mb-2">{data?.product_name}</div>
          <div className="BestSeller-upper-right-price mb-2">
            {thousandsSeparator(data?.list_price)} đ
          </div>
          <div className="BestSeller-upper-right-textarea">
            <div>
              <span className="BestSeller-upper-right-textarea-title">Promotion:</span>
              <span
                className="BestSeller-upper-right-textarea-text ml-1"
                style={{ wordBreak: 'break-all' }}
              >
                <span>
                  {data?.promotion?.length <= 1 ? (
                    data?.promotion?.map((item, index) => {
                      return (
                        <span>
                          <span>
                            <span>{item}</span>
                          </span>
                        </span>
                      )
                    })
                  ) : (
                    <span>
                      <span>
                        <span>{data?.promotion[0]}</span>
                      </span>
                      <Popover
                        content={
                          <span className="overflow-scroll" style={{ maxHeight: 552 }}>
                            {data?.promotion?.map((item, index) => {
                              if (index >= 1) return <div>{item}</div>
                            })}
                          </span>
                        }
                      >
                        <span className="ml-1" style={{ color: '#1890FF' }}>{` + ${
                          data?.promotion?.length - 1
                        } other promotions`}</span>
                      </Popover>
                    </span>
                  )}
                </span>
              </span>
            </div>
            <Divider />
            <div>
              <span className="BestSeller-upper-right-textarea-title">Supplier:</span>
              <span className="BestSeller-upper-right-textarea-text ml-1">{data?.supplier}</span>
            </div>
            <div className="mt-2">
              <span className="BestSeller-upper-right-textarea-title">Sale Channel:</span>
              <span
                className="BestSeller-upper-right-textarea-text ml-1"
                style={{ wordBreak: 'break-all' }}
              >
                <span>
                  {data?.sale_channel?.split(',')?.length <= 2 ? (
                    // data?.sale_channel?.split(',')?.map((item, index) => {
                    // return (
                    <span>
                      <span>
                        <span>
                          {data?.sale_channel?.split(',')[0] +
                            ', ' +
                            data?.sale_channel?.split(',')[1]}
                        </span>
                      </span>
                    </span>
                  ) : (
                    // )
                    // })
                    <span>
                      <span>
                        <span>{data?.sale_channel?.split(',')[0]}</span>
                      </span>
                      <Popover
                        content={
                          <span className="overflow-scroll" style={{ maxHeight: 552 }}>
                            {data?.sale_channel?.split(',')?.map((item, index) => {
                              if (index >= 1) return <div>{item}</div>
                            })}
                          </span>
                        }
                      >
                        <span className="ml-1" style={{ color: '#1890FF' }}>{` + ${
                          data?.sale_channel?.split(',')?.length - 1
                        } more`}</span>
                      </Popover>
                    </span>
                  )}
                </span>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="BestSeller-lower-area flex flex-row">
        <div className="BestSeller-lower-left">
          <div className="flex flex-row justify-between hightlight-background">
            <div className="BestSeller-lower-title">Sold item</div>
            <div className="BestSeller-lower-text">{thousandsSeparator(data?.sold_item)}</div>
          </div>
          <div className="flex flex-row justify-between mt-2">
            <div className="BestSeller-lower-title">Gross Margin</div>
            <div className="BestSeller-lower-text">{data?.gross_margin?.toFixed(0)}%</div>
          </div>
          <div className="flex flex-row justify-between mt-2 hightlight-background">
            <div className="BestSeller-lower-title">Customers</div>
            <div className="BestSeller-lower-text">
              {thousandsSeparator(data?.no_of_new_customers)}
            </div>
          </div>
          <div className="flex flex-row justify-between mt-2">
            <div className="BestSeller-lower-title">Returned Customers</div>
            <div className="BestSeller-lower-text">
              {thousandsSeparator(data?.no_of_returned_customers)}
            </div>
          </div>
          <div className="flex flex-row justify-between mt-2 hightlight-background">
            <div className="BestSeller-lower-title">Revenue</div>
            <div className="BestSeller-lower-text">{thousandsSeparator(data?.revenue)} đ</div>
          </div>
          <div className="flex flex-row justify-between mt-2">
            <div className="BestSeller-lower-title">% Total Rev</div>
            <div className="BestSeller-lower-text">{data?.total_rev?.toFixed(2)}%</div>
          </div>
          <div className="flex flex-row justify-between mt-2 hightlight-background">
            <div className="BestSeller-lower-title">{`DRR (items/day)`}</div>
            <div className="BestSeller-lower-text">{data?.drr?.toFixed(0)}</div>
          </div>
        </div>
        <div className="BestSeller-lower-right">
          <div className="flex flex-row justify-between hightlight-background">
            <div className="BestSeller-lower-title">Inventory</div>
            <div className="BestSeller-lower-text">{thousandsSeparator(data?.inventory)}</div>
          </div>
          <div className="flex flex-row justify-between mt-2">
            <div className="BestSeller-lower-title">Day to clear</div>
            <div className="BestSeller-lower-text">{thousandsSeparator(data?.inventory_days)}</div>
          </div>
          {/* <div className="flex flex-row justify-between mt-2 hightlight-background">
            <div className="BestSeller-lower-title">Pageview</div>
            <div className="BestSeller-lower-text">{data?.page_view}</div>
          </div> */}
          <div className="flex flex-row justify-between mt-2  hightlight-background">
            <div className="BestSeller-lower-title">Cost Price</div>
            <div className="BestSeller-lower-text">{thousandsSeparator(data?.cost_price)} đ</div>
          </div>
          <div className="flex flex-row justify-between mt-2">
            <div className="BestSeller-lower-title">Unit</div>
            <div className="BestSeller-lower-text">{data?.unit}</div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default BestSeller_Card
