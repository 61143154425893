import React, { useEffect, useState } from 'react'
import { message, Pagination, Skeleton, Table, Tree } from 'antd'
import { ComputeTypeCard, ComputeTypeColumns } from './constants'
import { getCategoryList } from 'Services/Analytics/saleChannel-analytics'
import { ReactComponent as IconSort } from 'Assets/images/icons/sorter.svg'
import { fontSize } from 'DesignSystem'
import './index.scss'
import BestSeller_Card from './Components/BestSeller_Card'
import BrandRanking_Card from './Components/BrandRanking_Card'
import SupplierRanking_Card from './Components/SupplierRanking_Card'
import SubCateSale_Card from './Components/SubCateSale_Card'
import { FiltersType } from 'AnalyticsTypes'
import axios from 'axios'
import queryString from 'query-string'
import { useHistory, useParams } from 'react-router-dom'
import useFilters from 'Pages/Analytics/Components/FiltersHeader/useFilters'
import { getProductCategoryList } from 'Services/Analytics/product-analytics'
import { DataSourceContext } from 'Pages/Analytics/Products/Overview/index'
interface BestSellerDataType {
  key: number
  ranking: number
  product_name: string
  product_performance: string
  inventory_status: string
  category_role: string
  image: string
  sale_channel: string
  supplier: string
  sold_item: number
  gross_margin: number
  no_of_new_customers: number
  no_of_returned_customer: number
  revenue: number
  total_rev: number
  ddr: number
  inventory: number
  inventory_days: number
  cost_price: number
  list_price: number
  promotion: string
  unit: string
}
const CateTable = ({
  category_id,
  order_by,
  analytic_type,
  type,
  view,
  handleDataFunction,
}: {
  category_id?: string
  order_by?: string
  analytic_type?: string
  type?: string
  view?: string
  handleDataFunction: (filters: FiltersType) => Promise<any>
}) => {
  const dataSource = React.useContext(DataSourceContext)
  const history = useHistory()
  const { id } = useParams()
  const { filters: defaultFilters } = useFilters()
  const [categoryList, setCategoryList] = useState()
  const [cateId, setCateId] = useState<any>('root')
  const [columnType, setColumnType] = useState<any>('')
  const [toggle, setToggle] = useState<boolean>(true)
  const queryObject = queryString.parse(history.location.search)
  const [error, setError] = useState('')
  const [data, setData] = useState<any>([])
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [totalPage, setTotalPage] = useState<number>(0)
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [pageSize, setPageSize] = useState<any>(20)
  // const [isCate, setIsCate] = useState<boolean>(false)
  const handleCateData = async () => {
    try {
      const resp = await getProductCategoryList({
        analytic_type: analytic_type,
        source: dataSource,
      })
      setCategoryList(resp.data.children)
    } catch (err: any) {
      message.error('Something went wrong')
    }
  }
  useEffect(() => {
    setCateId(category_id)
  }, [category_id])
  useEffect(() => {
    let cancelTokenSource = axios.CancelToken.source()
    setIsLoading(true)
    const fetchData = async () => {
      try {
        setError('')
        // setChart(CHART)
        const resp = await handleDataFunction({
          from_date: queryObject?.from_date?.toString() || defaultFilters?.from_date,
          to_date: queryObject?.to_date?.toString() || defaultFilters?.to_date,
          type: type,
          category_id: cateId,
          order_by: order_by,
          dimensions: JSON.parse(queryObject?.dimensions?.toString() || '[]'),
          page: currentPage,
          per_page: pageSize,
          frequency: queryObject?.frequency?.toString() || defaultFilters?.frequency,
          filter_uuid: queryObject?.filter_uuid?.toString() || '',
          source: dataSource?.toString() || '',
          cancelTokenSource,
        })
        setData(resp?.data?.results || resp?.data)
        setTotalPage(resp?.data?.count)
        // setIsCate(false)
      } catch (err: any) {
        if (err.message === 'cancelled') return
        const errorMessage: string =
          typeof err?.response?.data?.detail === 'string'
            ? err?.response?.data?.detail
            : 'No data available'
        setError(errorMessage)
      }
      setIsLoading(false)
    }

    fetchData()
    return () => {
      setData(undefined)
      cancelTokenSource.cancel('cancelled')
      // avoid race condition
    }
  }, [
    category_id,
    order_by,
    type,
    cateId,
    queryObject.from_date,
    queryObject.to_date,
    queryObject.frequency,
    queryObject.dimensions,
    handleDataFunction,
    currentPage,
    pageSize,
    dataSource,
  ])
  useEffect(() => {
    handleCateData()
  }, [dataSource])
  useEffect(() => {
    setColumnType(ComputeTypeColumns(type))
  }, [type])
  const windowWidth = window.innerWidth
  const renderTableMaxWidth = (val) => {
    if (val >= 1536) {
      if (val >= 1707) {
        if (val >= 1920) {
          if (val >= 2048) {
            return '91%'
          }
          return '83%'
        }
        return '81%'
      }
      return '80%'
    } else {
      return '77%'
    }
  }

  return (
    <div className="flex flex-col">
      <div className="flex CateTable-wrapper">
        <div style={{ backgroundColor: '#f5f5f5' }}>
          <div
            className="bg-white cursor-pointer justify-center items-center"
            style={{
              display: toggle ? 'none' : 'flex',
              width: 24,
              height: 40,
              fontSize: 18,
              transform: 'translateX(-16px)',
              borderRadius: '0 4px 4px 0',
              top: 146,
              position: 'absolute',
              boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.12)',
              zIndex: 1,
            }}
            onClick={() => setToggle(true)}
          >
            {`>`}
          </div>
        </div>
        <div
          className="relative bg-white left-corner-radius"
          style={{ width: 304, display: toggle ? '' : 'none' }}
        >
          <div
            style={{
              height: type === 'best_seller' ? 63 : type == 'brand_ranking' ? 105.5 : 86.5,
              background: 'var(--table_header)',
              fontWeight: 600,
            }}
            className="flex p-4 text-sm border-b top-left-corner-radius text-secondary_text justify-between items-center"
          >
            <div className="flex" style={{ color: '#000000' }}>
              Categories
              {/* <IconSort className="ml-2 cursor-pointer"></IconSort> */}
            </div>
            <div
              style={{ cursor: 'pointer', fontSize: 18 }}
              onClick={() => setToggle(false)}
            >{`<`}</div>
          </div>

          <Tree
            treeData={categoryList}
            onSelect={(checkedKeys) => {
              setCateId(checkedKeys[0])
              setCurrentPage(1)
              // setIsCate(true)
            }}
          />
        </div>
        <div className="flex items-center justify-between mb-4">
          {/* <div className="flex text-brand_primary">
          <div className="mr-4">
            <RefreshIcon />
          </div>
          <div>
            <DownIcon />
          </div>
        </div> */}
        </div>
        <Skeleton paragraph={{ rows: 25 }} loading={isLoading} active className="h-full">
          {view === 'grid' ? (
            <div className="flex-1 CateTable-grid-container">
              {/* <div className="Products-grid-title">Product Info</div> */}
              <div className="flex-1 grid Products-grid overflow-scroll">
                {data?.map((item, index) => {
                  return ComputeTypeCard(type, item)
                  // return <BestSeller_Card data={item}></BestSeller_Card>
                })}
              </div>
            </div>
          ) : (
            data && (
              <Table
                className={`h-full CateTableTable bg-white CateTable-${type}  ${
                  toggle ? 'full-width' : ''
                }`}
                style={{ maxWidth: toggle ? renderTableMaxWidth(windowWidth) : '100%' }}
                columns={columnType}
                dataSource={data}
                bordered={false}
                size="middle"
                scroll={{
                  x: 800,
                  y: 804,
                }}
                pagination={false}
                // onRow={(record, rowIndex) => {
                //   return {
                //     onMouseEnter: (event) => {
                //       // console.log(record, rowIndex)
                //     },
                //   }
                // }}
              />
            )
          )}
        </Skeleton>
      </div>

      <Pagination
        size="small"
        total={totalPage}
        pageSize={pageSize}
        onChange={(page, page_size) => {
          setCurrentPage(page)
          setPageSize(page_size)
        }}
        showSizeChanger
        showQuickJumper
        current={currentPage}
        className="self-end mt-4"
      />
    </div>
  )
}

export default CateTable
