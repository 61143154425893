import React from 'react'
import StatusBadge from './StatusBadge'
import './index.scss'
import kFormatter, { thousandsSeparator } from 'Utils/kFormatter'
import { BrandRankingDataType } from '../constants'
import { Popover } from 'antd'

const BrandRanking_Card = ({ data }: { data: BrandRankingDataType }) => {
  return (
    <div className="BrandRanking-wrapper flex flex-col bg-white mb-4">
      <div className="BrandRanking-header flex flex-row justify-between items-center">
        <div className="BrandRanking-header-left flex">
          <div className="BrandRanking-header-left-ranking"> #{data?.ranking}</div>
          <div className="BrandRanking-header-left-title ml-2 font-semibold">
            {data?.brand_name}
          </div>
        </div>
        <div className="BrandRanking-header-right flex flex-col">
          <div className="BrandRanking-header-right-title mb-1">Brand Performance</div>
          <div className="BrandRanking-header-right-status">
            <StatusBadge status={data?.brand_performance}></StatusBadge>
          </div>
        </div>
      </div>
      <div className="mt-4" style={{ height: 44 }}>
        <span className="SubCateSale-storage" style={{ fontWeight: 600 }}>
          Storage:{' '}
        </span>
        <span className="ml-1" style={{ wordBreak: 'break-all' }}>
          <span>
            {data?.storage?.length <= 1 ? (
              data?.storage?.map((item, index) => {
                return (
                  <span>
                    <span>
                      <span>{item}</span>
                    </span>
                  </span>
                )
              })
            ) : (
              <span>
                <span>
                  <span>{data?.storage[0]}</span>
                </span>
                <Popover
                  content={
                    <span className="overflow-scroll" style={{ maxHeight: 552 }}>
                      {data?.storage?.map((item, index) => {
                        if (index >= 1) return <div>{item}</div>
                      })}
                    </span>
                  }
                >
                  <span className="ml-1" style={{ color: '#1890FF' }}>{` + ${
                    data?.storage?.length - 1
                  } other promotions`}</span>
                </Popover>
              </span>
            )}
          </span>
        </span>
      </div>
      <div className="BrandRanking-body flex justify-between">
        <div className="BrandRanking-body-left">
          <div className="flex flex-row justify-between mt-2 hightlight-background">
            <div className="BrandRanking-body-left-title ">Purchase Value</div>
            <div className="BrandRanking-body-left-text">{kFormatter(data?.purchased_value)}</div>
          </div>
          <div className="flex flex-row justify-between mt-2">
            <div className="BrandRanking-body-left-title">Revenue</div>
            <div className="BrandRanking-body-left-text">
              {data?.revenue ? kFormatter(data?.revenue) : 0}
            </div>
          </div>
          <div className="flex flex-row justify-between mt-2 hightlight-background">
            <div className="BrandRanking-body-left-title">Gross Profit</div>
            <div className="BrandRanking-body-left-text">
              {thousandsSeparator(data?.gross_profit)}đ
            </div>
          </div>
          <div className="flex flex-row justify-between mt-2">
            <div className="BrandRanking-body-left-title">% Gross Profit</div>
            <div className="BrandRanking-body-left-text">
              {data?.ratio_gross_profit?.toFixed(0)}%
            </div>
          </div>
          <div className="flex flex-row justify-between mt-2 hightlight-background">
            <div className="BrandRanking-body-left-title ">New Customer</div>
            <div className="BrandRanking-body-left-text">
              {thousandsSeparator(data?.new_customer)}
            </div>
          </div>
          <div className="flex flex-row justify-between mt-2">
            <div className="BrandRanking-body-left-title">Return Customer</div>
            <div className="BrandRanking-body-left-text">
              {thousandsSeparator(data?.return_customer)}
            </div>
          </div>
          <div className="flex flex-row justify-between mt-2 hightlight-background">
            <div className="BrandRanking-body-left-title ">Revenue/</div>
            <div className="BrandRanking-body-left-text">
              {thousandsSeparator(data?.revenue_per_customer)}
            </div>
          </div>
          <div className="flex flex-row justify-between hightlight-background">
            <div className="BrandRanking-body-left-title ">Customer</div>
          </div>
          <div className="flex flex-row justify-between mt-2">
            <div className="BrandRanking-body-left-title">Inventory Days</div>
            <div className="BrandRanking-body-left-text">
              {data?.inventory_days?.toFixed(0)}days
            </div>
          </div>
        </div>
        <div className="BrandRanking-body-right">
          <div className="BrandRanking-body-right-sku mt-2">SKU</div>
          <div className="flex flex-row justify-between mt-2">
            <div className="BrandRanking-body-right-title">Total</div>
            <div className="BrandRanking-body-right-text">{data?.total_of_sku}</div>
          </div>
          <div className="BrandRanking-body-right-group mt-2">
            <div className="BrandRanking-body-right-group-title hightlight-background">
              Product Performance
            </div>
            <div className="flex flex-row justify-between mt-2">
              <div className="BrandRanking-body-right-title">Total</div>
              <div className="BrandRanking-body-right-text">
                {thousandsSeparator(data?.total_of_product_performance)}
              </div>
            </div>
            <div className="flex flex-row justify-between mt-2">
              <div className="BrandRanking-body-right-title">New</div>
              <div className="BrandRanking-body-right-text">{thousandsSeparator(data?.new)}</div>
            </div>
            <div className="flex flex-row justify-between mt-2">
              <div className="BrandRanking-body-right-title">Red</div>
              <div className="BrandRanking-body-right-text">{thousandsSeparator(data?.red)}</div>
            </div>
            <div className="flex flex-row justify-between mt-2">
              <div className="BrandRanking-body-right-title">Green</div>
              <div className="BrandRanking-body-right-text">{thousandsSeparator(data?.green)}</div>
            </div>
          </div>
          <div className="BrandRanking-body-right-group mt-2">
            <div className="BrandRanking-body-right-group-title hightlight-background">
              Category Role
            </div>
            <div className="flex flex-row justify-between mt-2">
              <div className="BrandRanking-body-right-title">Total</div>
              <div className="BrandRanking-body-right-text">
                {thousandsSeparator(data?.total_of_category_role)}
              </div>
            </div>
            <div className="flex flex-row justify-between mt-2">
              <div className="BrandRanking-body-right-title">Traffic</div>
              <div className="BrandRanking-body-right-text">
                {thousandsSeparator(data?.traffic)}
              </div>
            </div>
            {/* <div className="flex flex-row justify-between mt-2">
              <div className="BrandRanking-body-right-title">Profit</div>
              <div className="BrandRanking-body-right-text">{thousandsSeparator(data?.profit)}</div>
            </div>
            <div className="flex flex-row justify-between mt-2">
              <div className="BrandRanking-body-right-title">Image</div>
              <div className="BrandRanking-body-right-text"></div>
            </div> */}
          </div>
          <div className="BrandRanking-body-right-group mt-2">
            <div className="BrandRanking-body-right-group-title hightlight-background">
              Sale Channel
            </div>
            <div className="flex flex-row justify-between mt-2">
              <div className="BrandRanking-body-right-title">Total</div>
              <div className="BrandRanking-body-right-text">
                {thousandsSeparator(data?.total_of_sale_channel)}
              </div>
            </div>
            <div className="flex flex-row justify-between mt-2">
              <div className="BrandRanking-body-right-title">Web</div>
              <div className="BrandRanking-body-right-text">{thousandsSeparator(data?.web)}</div>
            </div>
            <div className="flex flex-row justify-between mt-2">
              <div className="BrandRanking-body-right-title">App</div>
              <div className="BrandRanking-body-right-text">{thousandsSeparator(data?.app)}</div>
            </div>
            <div className="flex flex-row justify-between mt-2">
              <div className="BrandRanking-body-right-title">Kingfoodmart</div>
              <div className="BrandRanking-body-right-text">
                {thousandsSeparator(data?.kingfoodmart_mini)}
              </div>
            </div>
            <div className="flex flex-row justify-between">
              <div className="BrandRanking-body-right-title">Mini</div>
            </div>
            <div className="flex flex-row justify-between mt-2">
              <div className="BrandRanking-body-right-title">Kingfoodmart</div>
              <div className="BrandRanking-body-right-text">
                {thousandsSeparator(data?.kingfoodmart)}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default BrandRanking_Card
