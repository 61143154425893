import React from 'react'
import StatusBadge from './StatusBadge'
import './index.scss'
import { SubCateSaleDataType } from '../constants'
import kFormatter, { thousandsSeparator } from 'Utils/kFormatter'

const SubCateSale_Card = ({ data }: { data: SubCateSaleDataType }) => {
  return (
    <div className="SubCateSale-wrapper flex flex-col bg-white mb-4">
      <div className="SubCateSale-header flex flex-row justify-between">
        <div className="SubCateSale-header-left flex">
          <div className="SubCateSale-header-left-ranking"> #{data?.ranking}</div>
          <div className="SubCateSale-header-left-title ml-2 font-semibold">
            {data?.sub_categories_name}
          </div>
        </div>
        <div className="SubCateSale-header-right flex flex-col">
          <div className="SubCateSale-header-right-title mb-1">Inventory Status</div>
          <div className="SubCateSale-header-right-status">
            <StatusBadge status={data?.inventory_status}></StatusBadge>
          </div>
        </div>
      </div>
      <div className="flex">
        <div className="SubCateSale-storage" style={{ fontWeight: 600 }}>
          Sale Channel:{' '}
        </div>
        <div className="ml-1">{data?.sale_channel}</div>
      </div>
      <div className="SubCateSale-body flex justify-between">
        <div className="SubCateSale-body-left">
          <div className="flex flex-row justify-between mt-2 hightlight-background">
            <div className="SubCateSale-body-left-title ">Revenue</div>
            <div className="SubCateSale-body-left-text">{kFormatter(data?.revenue)}</div>
          </div>
          <div className="flex flex-row justify-between mt-2">
            <div className="SubCateSale-body-left-title">Gross Profit</div>
            <div className="SubCateSale-body-left-text">
              {data?.ratio_gross_profit?.toFixed(0)}%
            </div>
          </div>
          <div className="flex flex-row justify-between mt-2 hightlight-background">
            <div className="SubCateSale-body-left-title">No. of New</div>
            <div className="SubCateSale-body-left-text">
              {thousandsSeparator(data?.new_customer)}
            </div>
          </div>
          <div className="flex flex-row justify-between hightlight-background">
            <div className="SubCateSale-body-left-title">Customer</div>
          </div>
          <div className="flex flex-row justify-between mt-2">
            <div className="SubCateSale-body-left-title">No. of Return</div>
            <div className="SubCateSale-body-left-text">
              {thousandsSeparator(data?.return_customer)}
            </div>
          </div>
          <div className="flex flex-row justify-between">
            <div className="SubCateSale-body-left-title">Customer</div>
          </div>
          <div className="flex flex-row justify-between mt-2 hightlight-background">
            <div className="SubCateSale-body-left-title">Avg Sales/</div>
            <div className="SubCateSale-body-left-text">
              {thousandsSeparator(data?.revenue_per_customer)}
            </div>
          </div>
          <div className="flex flex-row justify-between hightlight-background">
            <div className="SubCateSale-body-left-title">Customer</div>
          </div>
          <div className="flex flex-row justify-between mt-2">
            <div className="SubCateSale-body-left-title">Inventory Days</div>
            <div className="SubCateSale-body-left-text">
              {data?.inventory_days?.toFixed(0)} days
            </div>
          </div>
          <div className="flex flex-row justify-between mt-2 hightlight-background">
            <div className="SubCateSale-body-left-title">Inventory Value</div>
            <div className="SubCateSale-body-left-text">{kFormatter(data?.inventory_value)}</div>
          </div>
          <div className="flex flex-row justify-between mt-2">
            <div className="SubCateSale-body-left-title">Daily Sales</div>
            <div className="SubCateSale-body-left-text">{kFormatter(data?.daily_sales)}</div>
          </div>
        </div>
        <div className="SubCateSale-body-right">
          <div className="SubCateSale-body-right-sku mt-2">SKU</div>
          <div className="SubCateSale-body-right-group mt-2">
            <div className="flex flex-row justify-between mt-2 hightlight-background">
              <div className="SubCateSale-body-right-title">Total</div>
              <div className="SubCateSale-body-right-text">{thousandsSeparator(data?.total)}</div>
            </div>
            <div className="flex flex-row justify-between mt-2">
              <div className="SubCateSale-body-right-title">New</div>
              <div className="SubCateSale-body-right-text">{thousandsSeparator(data?.new)}</div>
            </div>
            <div className="flex flex-row justify-between mt-2 hightlight-background">
              <div className="SubCateSale-body-right-title">Red</div>
              <div className="SubCateSale-body-right-text">{thousandsSeparator(data?.red)}</div>
            </div>
            <div className="flex flex-row justify-between mt-2 ">
              <div className="SubCateSale-body-right-title">Green</div>
              <div className="SubCateSale-body-right-text">{thousandsSeparator(data?.green)}</div>
            </div>
            <div className="flex flex-row justify-between mt-2 hightlight-background">
              <div className="SubCateSale-body-right-title">Yellow</div>
              <div className="SubCateSale-body-right-text">{thousandsSeparator(data?.yellow)}</div>
            </div>
            <div className="flex flex-row justify-between mt-2 ">
              <div className="SubCateSale-body-right-title">Star</div>
              <div className="SubCateSale-body-right-text">{thousandsSeparator(data?.star)}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SubCateSale_Card
